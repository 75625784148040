html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  background: none;
  background-color: transparent;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
